import { storeToRefs } from 'pinia';
import { useAuthStore } from '~/stores/auth';
import { useNavMenuStore } from '~~/stores/navigation/menus';
import { useToast } from 'vue-toastification';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const auth = useAuthStore();
  const toast = useToast();
  useCookie('redirect', { path: '/login' }).value = '';
  if (!auth.isLoggedIn) {
    useCookie('redirect', { path: '/login' }).value = to.fullPath;
    return auth.logout();
  }

  try {
    if (!auth.loadWorkspaceFromRoute(to)) {
      if (!auth.loadFirstWorkspaceFromUser()) {
        return auth.logout();
      }
    }
  } catch (e) {
    return auth.logout();
  }

  if (!auth.permissions) {
    auth.setUserPermissions();
  }

  // if user access home redirect him to first menu item
  if (to?.name === 'index') {
    const { allowedItems } = storeToRefs(useNavMenuStore());
    if (allowedItems.value[0]) {
      return navigateTo({ name: allowedItems.value[0].name, params: allowedItems.value[0].params });
    } else {
      toast.error('Erreur de navigation');
      console.error('No menu items found for user');
    }
  }
});
